<template>
<div>
  <div class="tops">
    <div style="margin-top:3%">店铺名称：<label>{{TsInfo.ShopName}}</label></div>
    <div><font color="#de3f00">店铺地址：</font><label>{{TsInfo.ShopDZ}}</label></div>
  </div>
  <div style="width: 90%; margin-left: 5%; line-height: 40px;">
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1">
          <span>投诉标题：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="layui-form-item">
          <div class="grid-demo" >
            <input type="text" class="layui-input" v-model="TsInfo.Title"  readonly="readonly"/>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>投诉内容：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <textarea class="layui-textarea"  v-model="TsInfo.tsnr" readonly="readonly"></textarea>
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>投诉照片：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <van-image
            width="200"
            height="200"

            :src='TsInfo.Pic'

            v>
        </van-image>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>姓名：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"   class="layui-input" v-model="TsInfo.Name" readonly="readonly" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>联系方式：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"   class="layui-input" v-model="TsInfo.Lxfs" readonly="readonly" />
        </div>
      </div>
    </div>


    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>当前状态：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"   class="layui-input" v-model="TsInfo.type" readonly="readonly" />
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import DES from "@/assets/js/DES";
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import Emas from "@/assets/js/Emas";

export default {
  name: "mqsbTs",
  data(){
    return{
      TsInfo:{
        ShopName:'',
        ShopDZ:'',
        Title:'',
        tsnr:'',
        Pic:'',
        Name:'',
        Lxfs:'',
        type:''
      },
      Info:'',
      ID:'',
      token:''
    }
  },created() {
    this.token = sessionStorage.getItem("token");
    this.ID=this.$route.query.ID
    this.fetchData()
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },methods:{
    async fetchData(){
      let strData='{"ID":"' + this.ID +'"}';
      //console.log(strData)
      //alert(strData)
      this.Info=DES.encryptDes(strData)
      // console.log(this.Info)
      // let a='{"result":"ok","msg":{"ShopName":"余姚市华珍服饰店","ShopDZ":"凤山街道-新建路-91-1号","Title":"门口非机动车乱停放","tsnr":"非机动车乱停","Pic":"http://113.31.115.78:8097/Upload/FileImg/2021010514483414_image.jpg","Name":"李","Lxfs":"13705845171","type":"已下派"}}';
      // let data=JSON.parse(a);
      // if (data.result=="ok"){
      //   if (data.msg!=''){
      //     this.TsInfo=data.msg
      //     console.log(this.TsInfo)
      //   }else {
      //   }
      //
      // }
      // return;
      mgop({
        api: "mgop.will.mqsb.SjMqSb", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: {token:this.token,type:'8', Info: this.Info },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          // alert(JSON.stringify(data))
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {
            if (data.data.msg!=''){
              this.TsInfo=data.data.msg
              console.log(this.TsInfo)
            }else {
            }

          } else if(data.data.result == "401"){
            Toast.fail('token失效，请重新登录');
            this.$router.push({
              path: '/index'
            })
          }else {
          }
        },
        onFail: (err) => {
          // alert(JSON.stringify(err))
          console.log("mgop run error. error :  " + JSON.stringify(err));
        },
      });
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
  background: #f5fbff;
}

.grid-demo {
  text-align: right;
}

.layui-input {
  width: 93%;
}

.layui-edge {
  right: 13% !important;
}

.layui-form-select dl {
  min-width: 93%;
}

span {
  line-height: 40px;
}

.layui-row {
  margin-top: 10px;
}


.tops {
  background: url(../../../assets/images/ts_02.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height:120px;
  font-size:18px;
  line-height:30px;
  padding:20px;
  color:#004e71;
}

</style>